/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  SearchBox,
  Hits,
  DynamicWidgets,
  RefinementList,
} from "react-instantsearch-dom";

import { Book } from "../Book";

const LibrarySearch = () => {
  const styles = css`
    display: flex;
    gap: 24px;

    .ais-Hits {
      flex: 1 0 auto;
      width: 100%;

      .ais-Hits-list {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        gap: 24px;

        @media screen and (max-width: 1560px) {
          grid-template-columns: repeat(6, 1fr);
        }

        @media screen and (max-width: 1280px) {
          grid-template-columns: repeat(4, 1fr);
        }

        @media screen and (max-width: 880px) {
          grid-template-columns: repeat(3, 1fr);
        }

        @media screen and (max-width: 664px) {
          grid-template-columns: repeat(2, 1fr);
        }

        @media screen and (max-width: 450px) {
          grid-template-columns: repeat(1, 1fr);
        }
      }

      .ais-Hits-item {
        width: 100%;
      }
    }
  `;
  return (
    <div css={styles} className="container">
      <Hits hitComponent={Book} />
    </div>
  );
};

export { LibrarySearch };
