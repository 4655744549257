/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { useStaticQuery, graphql, Link } from "gatsby";

const QUERY_FEATURED_POST = graphql`
  query FeaturedPost {
    allPrismicBlogPost(
      filter: { data: { status: { eq: "published" } } }
      sort: { fields: data___publish_date, order: DESC }
      limit: 1
    ) {
      nodes {
        id
        uid
        data {
          publish_date
          title {
            text
          }
        }
      }
    }
  }
`;

const FeaturedEntry = () => {
  const styles = css`
    max-width: 912px;

    @media screen and (max-width: 1440px) {
      max-width: 720px;
    }

    @media screen and (max-width: 1280px) {
      max-width: unset;
      margin-bottom: 32px;
    }

    @media screen and (max-width: 960px) {
      .t0 {
        font-size: 48px;
      }
    }

    @media screen and (max-width: 580px) {
      .t0 {
        font-size: 32px;
      }
    }

    a {
      color: inherit;
      line-height: 1.2;
    }
  `;

  const {
    allPrismicBlogPost: {
      nodes: [featuredPost],
    },
  } = useStaticQuery(QUERY_FEATURED_POST);

  const {
    id,
    uid,
    data: { title, publish_date },
  } = featuredPost;

  const year = publish_date.split("-")[0];

  return (
    <div css={styles}>
      <div className="t3">Now</div>
      <div className="t0">
        <Link to={`/journal/${year}/${uid}`}>{title.text}</Link>
      </div>
    </div>
  );
};
export { FeaturedEntry };
