/** @jsx jsx */
import { jsx, css, keyframes } from "@emotion/react";
import { useEffect, useState, createRef } from "react";
import ColorThief from "colorthief";
import ColorContrastChecker from "color-contrast-checker";
import colorString from "color-string";

interface MusicCardProps {
  artist: string;
  title: string;
  image: string;
  width?: string;
  link?: string;
  nowPlaying?: boolean;
}

const flash = keyframes`
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`;

const getContrast = (color) => {
  const ccc = new ColorContrastChecker();
  return ccc.isLevelAA(color, "#121212", 24);
};

const calculateColor = (currentRef, callback) => {
  //@ts-ignore
  const colorThief = new ColorThief();
  const colors = colorThief.getPalette(currentRef);
  const selectedColor = colors
    // @ts-ignore
    .map((color) => colorString.to.hex(color))
    .filter(getContrast)[0];

  // @ts-ignore
  callback(colorString.get.rgb(selectedColor) || [255, 255, 255]);
};

export const MusicCard = ({
  artist,
  title,
  image,
  width,
  nowPlaying = false,
}: MusicCardProps) => {
  const imageRef = createRef<any>();
  const [color, setColor] = useState([18, 18, 18]);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const currentRef = imageRef.current;
    currentRef.crossOrigin = "Anonymous";

    if (currentRef.complete) {
      calculateColor(currentRef, setColor);
      setVisible(true);
    } else {
      currentRef.addEventListener("load", () => {
        calculateColor(currentRef, setColor);
        setVisible(true);
      });
    }
  }, []);

  const styles = css`
    width: ${width || "288px"};

    .image {
      width: inherit;
      aspect-ratio: 1;
      margin-bottom: 24px;
      border-radius: 6px;
      overflow: hidden;
      position: relative;

      img {
        width: 100%;
        display: block;
        transform: scale(${visible ? 1 : 1.05});
        opacity: ${visible ? 1 : 0};
        transition: transform 1.2s ease-in-out, opacity 1.2s ease-in-out;

        @media (prefers-reduced-motion) {
          transform: scale(1);
          opacity: 1;
        }
      }

      .now-playing {
        position: absolute;
        top: 8px;
        left: 8px;
        z-index: 2;
        background: #ff3f00;
        padding: 5px 6px 5px 5px;
        line-height: 1;
        border-radius: 2px;
        display: flex;
        align-items: center;
        gap: 4px;

        &::before {
          width: 8px;
          height: 8px;
          background: white;
          content: "";
          display: block;
          border-radius: 10px;
          animation: ${flash} 1.2s ease-in-out infinite;
        }
      }
    }

    .t4 {
      margin-bottom: 8px;
    }

    .t3 {
      margin-bottom: 0;
      color: rgba(${color[0]}, ${color[1]}, ${color[2]});
      line-height: 1.2;
      transition: color 0.25s ease 0.125s;
    }
  `;

  return (
    <div className="MusicCard" css={styles}>
      <div className="image">
        <img ref={imageRef} src={image} alt="" />
        {nowPlaying && <div className="now-playing t5">Now playing</div>}
      </div>
      <div className="artist t4">{artist}</div>
      <div className="title t3">{title}</div>
    </div>
  );
};
