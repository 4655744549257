/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Link } from "gatsby";

import Wordmark from "../../../images/workmark.svg";

export const Footer = () => {
  const styles = css`
    max-width: 1537px;
    margin: auto;
    padding-bottom: 64px;
    margin-top: -40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    @media screen and (max-width: 580px) {
      padding-bottom: 24px;
      margin-top: -8px;
    }

    .wordmark {
      height: 40px;
      width: 40px;
      position: absolute;
      top: -8px;
      left: 0;
      right: 0;
      margin: auto;

      @media screen and (max-width: 580px) {
        display: none;
      }
    }

    @media screen and (max-width: 1585px) {
      padding-left: 24px;
      padding-right: 24px;
    }

    @media screen and (max-width: 380px) {
      .t4 {
        font-size: 14px !important;
      }
    }

    @media screen and (max-width: 320px) {
      padding-left: 16px;
      padding-right: 16px;
    }
  `;
  return (
    <div css={styles}>
      <div className="copy t4">
        &copy; {new Date().getFullYear()} Jack-Edward Oliver
      </div>
      <Link to="/">
        <img src={Wordmark} className="wordmark" alt="" />
      </Link>
      <div className="imprint t4">Made in 🇸🇪&nbsp;</div>
    </div>
  );
};
