/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { FeaturedEntry, RecentEntries, Weeknotes, Newsletter } from "./modules";

export const Journal = () => {
  const styles = css`
    max-width: 1537px;
    margin: 0 auto;

    @media screen and (max-width: 1585px) {
      padding-left: 24px;
      padding-right: 24px;
    }

    @media screen and (max-width: 320px) {
      padding-left: 16px;
      padding-right: 16px;
    }

    @media screen and (max-width: 640px) {
      .t1 {
        font-size: 32px;
      }
    }

    @media screen and (max-width: 360px) {
      .t1 {
        font-size: 28px;
      }
    }

    .t1 {
      margin-bottom: 40px;
    }

    .t3 {
      margin-bottom: 24px;
      opacity: 0.5;
    }

    .flex {
      display: flex;
      justify-content: space-between;
      margin-bottom: 64px;

      @media screen and (max-width: 1280px) {
        display: block;
      }
    }
  `;
  return (
    <div css={styles}>
      <div className="title t1">Journal</div>
      <div className="flex">
        <FeaturedEntry />
        <RecentEntries />
      </div>
      {/* <Weeknotes /> */}
      <Newsletter />
    </div>
  );
};
