/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { useStaticQuery, graphql, Link } from "gatsby";
import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  SearchBox,
  Pagination,
  Configure,
  connectStats,
} from "react-instantsearch-dom";

import { LibrarySearch } from "./modules";

const ALGOLIA_APP_ID = "5F1YIA8XUR";
const ALGOLIA_API_KEY = "54f84dd978952fd858b9d3875b7cebad";

const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_API_KEY);

export const Books = () => {
  const styles = css`
    max-width: 1537px;
    margin: 0 auto;
    position: relative;
    min-height: 408px;

    @media screen and (max-width: 1585px) {
      padding-left: 24px;
      padding-right: 24px;
    }

    .t1 {
      margin-bottom: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media screen and (max-width: 540px) {
        display: block;
      }

      @media screen and (max-width: 640px) {
        font-size: 32px;
      }

      @media screen and (max-width: 360px) {
        font-size: 28px;
      }

      .title {
        display: flex;
        gap: 14px;

        .count {
          opacity: 0.7;
        }
      }
    }

    .ais-SearchBox {
      &-form {
        width: 288px;
        position: relative;

        @media screen and (max-width: 540px) {
          margin-top: 24px;
          width: calc(100vw - 48px);
        }
      }

      &-input {
        appearance: none;
        background: rgba(0, 0, 0, 0.1);
        color: white;
        border: 1px solid rgba(255, 255, 255, 0.1);
        font: inherit;
        width: 100%;
        padding: 12px;
        font-size: 0.6rem;
        outline: none;
        border-radius: 4px;

        @media screen and (max-width: 540px) {
          font-size: 16px;
        }
      }

      &-submit {
        display: none;
      }

      &-reset {
        display: none;
      }
    }

    .controls {
      display: flex;
      align-items: center;
      gap: 24px;
    }

    .ais-Pagination {
      font-size: 0.7rem;
      @media screen and (max-width: 940px) {
        display: none;
      }

      &-list {
        display: flex;
        gap: 8px;
        position: relative;
        top: 2px;
      }

      &-item {
        background: #0b0b0b;
        width: 32px;
        height: 32px;
        display: flex;
        line-height: 1;
        margin: 0;
        padding: 0;
        border-radius: 32px;
        align-items: center;
        justify-content: center;

        a {
          width: inherit;
          height: inherit;
          display: flex;
          line-height: 1;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          color: inherit;
          position: relative;
          top: 1px;
          left: 1px;
        }

        &--selected {
          background: #454545;
        }
      }

      &-link {
      }
    }

    .controls {
      @media screen and (max-width: 480px) {
        display: none;
      }
      a {
        color: inherit;
        font-size: 0.8rem;
      }
    }
  `;

  const Stats = ({ nbHits, nbSortedHits }) => (
    <div className="count">{nbHits} </div>
  );

  const CustomStats = connectStats(Stats);

  return (
    <div css={styles}>
      <InstantSearch searchClient={searchClient} indexName="BOOKS">
        <Configure hitsPerPage={6} />
        <div className="t1">
          <div className="title">
            Books
            <CustomStats />
          </div>
          <div className="controls">
            <Link to="/books">Explore {`->`}</Link>
            {/* <Pagination showFirst={false} showLast={false} /> */}
            {/* <SearchBox /> */}
          </div>
        </div>
        <LibrarySearch />
      </InstantSearch>
    </div>
  );
};
