/** @jsx jsx */
import { useState, useEffect } from "react";
import { css, jsx } from "@emotion/react";
import { LastFM } from "plugins";
import { LastfmLogo } from "./LastfmLogo";
import { TopArtists, TopAlbums, RecentlyPlayed } from "./modules";

export const Music = () => {
  const styles = css`
    max-width: 1537px;
    margin: 0 auto;

    .split {
      display: flex;
      justify-content: space-between;
      margin-bottom: 64px;

      @media screen and (max-width: 960px) {
        display: block;
      }
    }

    > .title {
      display: flex;
      align-items: center;

      .totalPlays {
        margin-left: 16px;
        opacity: 0.7;
      }

      @media screen and (max-width: 640px) {
        display: block;

        .totalPlays {
          margin-left: 0;
        }
      }
    }

    .t1 {
      margin-bottom: 40px;
    }

    .t3 {
      margin-bottom: 24px;
    }

    .logo {
      background: rgba(255, 255, 255, 0.5);
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 40px;
      margin-right: 16px;
      transition: background 0.125s ease;

      path {
        transition: fill 0.125s ease;
      }

      &:hover {
        background: #ff3f00;

        path {
          fill: white;
        }
      }
    }

    @media screen and (max-width: 1585px) {
      padding-left: 24px;
      padding-right: 24px;
    }

    @media screen and (max-width: 640px) {
      .t1 {
        font-size: 32px;
      }
      .logo {
        display: none !important;
      }
    }

    @media screen and (max-width: 360px) {
      .t1 {
        font-size: 28px;
      }
    }

    @media screen and (max-width: 320px) {
      padding-left: 16px;
      padding-right: 16px;
    }
  `;

  const [totalPlays, setTotalPlays] = useState(null);

  useEffect(() => {
    LastFM()
      .User.getInfo()
      .then((response) => {
        setTotalPlays(parseInt(response.user.playcount));
      })
      .catch(console.error);
  }, []);

  return (
    <div css={styles}>
      <div className="title t1">
        <div className="logo">
          <LastfmLogo />
        </div>
        Music {totalPlays && <div className="totalPlays">{totalPlays}</div>}
      </div>
      <div className="split">
        <TopArtists />
        <TopAlbums />
      </div>
      <RecentlyPlayed />
    </div>
  );
};
