/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { useStaticQuery, graphql, Link } from "gatsby";

const QUERY_RECENT_ENTRIES = graphql`
  query RecentPosts {
    allPrismicBlogPost(
      filter: { data: { status: { eq: "published" } } }
      sort: { fields: data___publish_date, order: DESC }
      limit: 3
      skip: 1
    ) {
      nodes {
        id
        uid
        data {
          publish_date
          title {
            text
          }
        }
      }
    }
  }
`;

const RecentEntries = () => {
  const styles = css`
    width: 392px;
    margin-right: 104px;

    @media screen and (max-width: 1280px) {
      width: 100%;
    }

    .t3 {
      margin-bottom: 32px;
    }

    .t4 {
      line-height: 1.5;
    }

    li {
      margin-bottom: 16px;

      a {
        color: inherit;
      }
    }
  `;

  const {
    allPrismicBlogPost: { nodes: posts },
  } = useStaticQuery(QUERY_RECENT_ENTRIES);

  return (
    <div css={styles}>
      <div className="t3">Before</div>
      <ul className="t4">
        {posts.map(({ id, uid, data: { title, publish_date } }) => {
          const year = publish_date.split("-")[0];
          return (
            <li key={id}>
              <Link to={`/journal/${year}/${uid}`}>{title.text}</Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
export { RecentEntries };
