const API_KEY = process.env.GATSBY_LASTFM_API_KEY;
const API_ROOT = "https://ws.audioscrobbler.com/2.0/";
const USER = "jv-la";

const methods = {
  user: {
    getInfo: "user.getinfo",
    getRecentTracks: "user.getrecenttracks",
    getTopAlbums: "user.gettopalbums",
    getTopArtists: "user.gettopartists",
  },
};

const LastFM = () => {
  const doFetch = (method, limit = null) =>
    limit
      ? fetch(
          `${API_ROOT}?method=${method}&user=${USER}&api_key=${API_KEY}&format=json&limit=${limit}`
        )
      : fetch(
          `${API_ROOT}?method=${method}&user=${USER}&api_key=${API_KEY}&format=json`
        );
  return {
    User: {
      getInfo: () =>
        doFetch(methods.user.getInfo).then((response) => response.json()),
      getRecentTracks: (limit) =>
        doFetch(methods.user.getRecentTracks, limit || 0).then((response) =>
          response.json()
        ),
      getTopAlbums: (limit) =>
        doFetch(methods.user.getTopAlbums, limit || 0).then((response) =>
          response.json()
        ),
      getTopArtists: (limit) =>
        doFetch(methods.user.getTopArtists, limit || 0).then((response) =>
          response.json()
        ),
    },
  };
};

export { LastFM };
