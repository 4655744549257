/** @jsx jsx */
import { useState, useEffect } from "react";
import { jsx, css } from "@emotion/react";
import { LastFM } from "plugins";

import { MusicCard } from "../../MusicCard";
import { findImage } from "../../utils";

export const RecentlyPlayed: React.FC = () => {
  const styles = css`
    max-width: 1537px;
    min-height: 452px;

    > .t3 {
      opacity: 0.6;
    }

    .grid-wrap {
      overflow-x: auto;
      scroll-snap-type: x mandatory;
      width: 100%;
    }

    .grid {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      gap: 24px;
      margin-bottom: 40px;
      box-sizing: border-box;

      @media screen and (max-width: 1080px) {
        grid-template-columns: repeat(4, 1fr);
      }

      @media screen and (max-width: 740px) {
        grid-template-columns: repeat(3, 1fr);
        gap: 16px !important;
      }

      @media screen and (max-width: 520px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 8px;
        row-gap: 40px !important;
      }

      @media screen and (max-width: 400px) {
        grid-template-columns: repeat(7, 1fr);
        width: 350%;
        gap: 8px !important;

        .MusicCard {
          scroll-snap-align: start;
        }
      }

      @media screen and (max-width: 380px) {
        width: 700%;
      }
    }

    @media screen and (max-width: 1585px) {
      .MusicCard {
        width: 100%;
      }
    }
  `;

  const [recentTracks, setRecentTracks] = useState([]);

  const isNowPlaying = (node) => (node["@attr"] ? false : true);

  useEffect(() => {
    LastFM()
      .User.getRecentTracks(7)
      .then((response) => {
        setRecentTracks(
          response.recenttracks.track.map((node) => ({
            name: node.name,
            nowPlaying: node["@attr"] ? true : false,
            artist: node.artist["#text"],
            images: node.image.map((imageNode) => ({
              size: imageNode.size,
              text: imageNode["#text"],
            })),
          }))
        );
      })
      .catch(console.error);
  }, []);

  const hasNowPlaying = recentTracks.filter(isNowPlaying)[0] ? true : false;

  return (
    <div css={styles}>
      <div className="t3">Recently Played</div>
      <div className="grid-wrap">
        <div className="grid">
          {recentTracks.map((track, i) => {
            if (hasNowPlaying) {
              if (i < 7) {
                return (
                  <MusicCard
                    key={`track-${i}`}
                    title={track.name}
                    artist={track.artist}
                    image={findImage({
                      size: "extralarge",
                      imageNodes: track.images,
                    })}
                    width={i === 0 ? "288px" : "184px"}
                    nowPlaying={track.nowPlaying}
                  />
                );
              }
            } else {
              return (
                <MusicCard
                  key={`track-${i}`}
                  title={track.name}
                  artist={track.artist}
                  image={findImage({
                    size: "extralarge",
                    imageNodes: track.images,
                  })}
                  width={i === 0 ? "288px" : "184px"}
                  nowPlaying={track.nowPlaying}
                />
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};
