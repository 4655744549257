import { Header } from "./Header";
import { Hero } from "./Hero";
import { Journal } from "./Journal";
import { Music } from "./Music";
import { Books } from "./Books";
import { Footer } from "./Footer";
import { ArticleAlert } from "./ArticleAlert";
import { NewsletterBlock } from "./NewsletterBlock";

const T22 = {
  Header,
  Hero,
  Journal,
  Music,
  Books,
  Footer,
  ArticleAlert,
  NewsletterBlock,
};

export { T22 };
