/** @jsx jsx */
import { useState } from "react";
import { css, jsx } from "@emotion/react";
import addToMailchimp from "gatsby-plugin-mailchimp";

type Status = null | 'error' | 'success'

const Newsletter = () => {
  const styles = css`
    box-sizing: border-box;
    position: relative;

    &::before {
      width: 240px;
      height: 2px;
      content: "";
      display: block;
      background: rgba(255, 255, 255, 0.1);
      margin-bottom: 24px;
    }

    .l {
      max-width: 700px;
      position: relative;

      p {
        @media screen and (max-width:720px) {
          font-size: 20px;
        }

        @media screen and (max-width:590px) {
          font-size: 16px;
        }

        strong {
          font-weight: 600;
        }
        em {
          font-style: italic;
        }
      }

      form {
        display: flex;
        gap: 8px;

        @media screen and (max-width:580px) {
          flex-direction: column;
        }

        input {
          appearance: none;
          background: white;
          border: none;
          border-radius: 4px;
          font: inherit;
          padding: 16px;
          font-size: 20px;
          min-width: 300px;
          outline: none;

          @media screen and (max-width:580px) {
            min-width: unset;
            // width: 100%;
            font-size: 16px;
          }
        }

        button {
          appearance: none;
          background: #232323;
          color: white;
          font: inherit;
          font-size: 20px;
          font-weight: 600;
          padding: 16px 24px;
          border-radius: 4px;
          border: none;
          transition: background 0.125s ease-in-out;
          cursor: pointer;

          @media screen and (max-width:580px) {
            font-size: 16px;
          }

          &::after {
            content: "->";
            margin-left: 8px;
            transform: translateX(0);
            display: inline-block;
            transition: transform 0.25s ease-in-out;
          }

          &:hover {
            background: #343434;

            &::after {
              transform: translateX(4px);
            }
          }
        }
      }

      .alert {
        padding: 16px;
        font-size: 20px;

        @media screen and (max-width:580px) {
          font-size: 16px;
          line-height: 1.2;
        }

        &.success {
          background: #0F884E;
        }

        &.error {
          background: #881D1D;
        }
      }
    }
  `;

  const [status, setStatus] = useState<Status>(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    const email = e.target[0].value;
    addToMailchimp(email)
    .then(data => {
      setStatus('success')
      console.log(data)
    }).catch(error => {
      setStatus('error')
      console.error(error)
    })
  };

  return (
    <div css={styles}>
      <div className="l">
        <p>
          <strong>Want updates?</strong> Drop your email.{" "}
          <em>No spam, pinky promise.</em>
        </p>
        {!status && (
          <form onSubmit={handleSubmit}>
            <input required type="email" placeholder="Enter your email..." />
            <button type="submit">Sign me up</button>
          </form>
        )}
        {status === 'success' && (
          <div className="alert success">Success! You'll get an email next time I publish something.</div>
        )}
        {status === 'error' && (
          <div className="alert error">Uh oh! Something went wrong. Please refresh and try again.</div>
        )}
      </div>
    </div>
  );
};

export { Newsletter };
