/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { useState } from "react";

const ArticleAlert = ({ children }) => {
  const styles = css`
    background: #ed4e1c;
    font-size: 0.7rem;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    padding: 16px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    .close {
      text-decoration: underline;
      cursor: pointer;
    }
  `;
  const [active, setActive] = useState(true);
  return active ? (
    <div css={styles}>
      {children}
      <div className="close" onClick={() => setActive(false)}>
        Close
      </div>
    </div>
  ) : null;
};

export { ArticleAlert };
