/** @jsx jsx */
import { useState, useEffect } from "react";
import { jsx, css, keyframes } from "@emotion/react";
import { LastFM } from "plugins";

const equalizer = keyframes`
  0% {
    width: 50%;
    filter: hue-rotate(0);
  }
  25% {
    width: 33%;
  }
  50% {
    width: 62%;
    filter: hue-rotate(180deg);
  }
  75% {
    width: 12%;
  }
  100% {
    width: 50%;
    filter: hue-rotate(360deg);
  }
`;

export const TopArtists = () => {
  const styles = css`
    width: 496px;
    min-height: 437px;

    .t3 {
      opacity: 0.6;
    }

    @media screen and (max-width: 544px) {
      width: 100%;
    }

    &:hover {
      li {
        .bar {
          background: #ff3f00;
        }
        &:nth-of-type(1) {
          .bar {
            animation: ${equalizer} 1s linear infinite;
          }
        }
        &:nth-of-type(2) {
          .bar {
            animation: ${equalizer} 1.125s linear infinite;
          }
        }
        &:nth-of-type(3) {
          .bar {
            animation: ${equalizer} 1.25s linear infinite;
          }
        }
        &:nth-of-type(4) {
          .bar {
            animation: ${equalizer} 1.375s linear infinite;
          }
        }
        &:nth-of-type(5) {
          .bar {
            animation: ${equalizer} 1.5s linear infinite;
          }
        }
        &:nth-of-type(6) {
          .bar {
            animation: ${equalizer} 1.625s linear infinite;
          }
        }
        &:nth-of-type(7) {
          .bar {
            animation: ${equalizer} 1.75s linear infinite;
          }
        }
      }
    }

    li {
      display: flex;
      margin-bottom: 4px;
      position: relative;

      .bar {
        width: 100%;
        background: #666;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 3px;
      }

      &:nth-of-type(2) {
        .bar {
          opacity: 0.85;
        }
      }

      &:nth-of-type(3) {
        .bar {
          opacity: 0.7;
        }
      }

      &:nth-of-type(4) {
        .bar {
          opacity: 0.55;
        }
      }

      &:nth-of-type(5) {
        .bar {
          opacity: 0.4;
        }
      }

      &:nth-of-type(6) {
        .bar {
          opacity: 0.25;
        }
      }

      &:nth-of-type(7) {
        .bar {
          opacity: 0.1;
        }
      }

      .t4 {
        position: relative;
        padding: 16px 0 16px 16px;

        &:last-child {
          opacity: 0.8;
        }
      }
    }
  `;

  const calculateWidth = (maxCount, count) => {
    return `${Math.floor((count / maxCount) * 100)}%`;
  };

  const [topArtists, setTopArtists] = useState([]);

  useEffect(() => {
    LastFM()
      .User.getTopArtists(7)
      .then((response) => {
        setTopArtists(
          response.topartists.artist.map((node) => ({
            name: node.name,
            count: node.playcount,
          }))
        );
      })
      .catch(console.error);
  }, []);

  return (
    <div css={styles}>
      <div className="t3">Top Artists</div>
      <ul>
        {topArtists.map((artist, i) => (
          <li key={artist.name}>
            <div
              className="bar"
              style={{
                width: calculateWidth(topArtists[0].count, artist.count),
              }}
            />
            <div className="t4">{artist.name}</div>
            <div className="t4">{artist.count}</div>
          </li>
        ))}
      </ul>
    </div>
  );
};
