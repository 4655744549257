/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { useStaticQuery, graphql, Link } from "gatsby";

const queryMetadata = graphql`
  query SiteMetadata {
    site {
      siteMetadata {
        title
        job {
          title
          company {
            name
            url
          }
        }
        contact {
          linkedIn
          twitter
          github
          email
        }
      }
    }
  }
`;

export const Hero = () => {
  const styles = css`
    max-width: 1537px;
    margin: 0 auto;
    padding-top: 64px;

    .t3 {
      line-height: 1.4;
      color: rgba(255, 255, 255, 0.8);

      a {
        color: rgba(255, 255, 255, 1);
      }
    }

    @media screen and (max-width: 1585px) {
      padding-left: 24px;
      padding-right: 24px;
    }

    @media screen and (max-width: 640px) {
      padding-top: 32px;
    }

    @media screen and (max-width: 440px) {
      .t3 {
        font-size: 20px !important;
      }
    }

    @media screen and (max-width: 400px) {
      .t3 {
        font-size: 18px !important;
      }
    }

    @media screen and (max-width: 320px) {
      padding-left: 16px;
      padding-right: 16px;
    }
  `;

  const {
    site: {
      siteMetadata: { title, job, contact },
    },
  } = useStaticQuery(queryMetadata);
  return (
    <div css={styles}>
      <div className="job t3">
        {job.title} @ <a href={job.company.url}>{job.company.name}</a>.
      </div>
      <div className="contact t3">
        Find me on <a href={contact.linkedIn}>LinkedIn</a> /{" "}
        <a href={contact.twitter}>Twitter</a> /{" "}
        <a href={contact.github}>Github</a>. Or you can{" "}
        <a href={`mailto:${contact.email}`}>email me</a>.
      </div>
    </div>
  );
};
