/** @jsx jsx */
import { useState } from "react";
import { jsx, css } from "@emotion/react";
import addToMailchimp from "gatsby-plugin-mailchimp";

import BG from "./bg.jpg";

type Status = null | "success" | "error";

const NewsletterBlock = () => {
  const styles = css`
    background: #121212;
    border-radius: 4px;
    max-width: 60ch;
    margin: 64px auto;
    padding: 24px;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      filter: grayscale(1);
      opacity: .2;
    }

    .l {
      position: relative;
    }

    form {
      display: flex;
      gap: 8px;

      @media screen and (max-width:580px) {
        flex-direction: column;
      }

      input {
        appearance: none;
        background: white;
        border: none;
        border-radius: 4px;
        font: inherit;
        padding: 16px;
        font-size: 20px;
        min-width: 300px;
        outline: none;

        @media screen and (max-width:580px) {
          min-width: unset;
          // width: 100%;
          font-size: 16px;
        }
      }

      button {
        appearance: none;
        background: #232323;
        color: white;
        font: inherit;
        font-size: 20px;
        font-weight: 600;
        padding: 16px 24px;
        border-radius: 4px;
        border: none;
        transition: background 0.125s ease-in-out;
        cursor: pointer;

        @media screen and (max-width:580px) {
          font-size: 16px;
        }

        &::after {
          content: "->";
          margin-left: 8px;
          transform: translateX(0);
          display: inline-block;
          transition: transform 0.25s ease-in-out;
        }

        &:hover {
          background: #343434;

          &::after {
            transform: translateX(4px);
          }
        }
      }
    }

    .alert {
      padding: 16px;
      font-size: 20px;

      @media screen and (max-width:580px) {
        font-size: 16px;
        line-height: 1.2;
      }

      &.success {
        background: #0F884E;
      }

      &.error {
        background: #881D1D;
      }
    }
  `;

  const [status, setStatus] = useState<Status>(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    const email = e.target[0].value;
    addToMailchimp(email)
    .then(data => {
      setStatus('success')
      console.log(data)
    }).catch(error => {
      setStatus('error')
      console.error(error)
    })
  };

  return (
    <div css={styles}>
      <img src={BG} alt="" />
      <div className="l">
        <p>
          <strong>Want updates?</strong> Drop your email.{" "}
          <em>No spam, pinky promise.</em>
        </p>
        {!status && (
          <form onSubmit={handleSubmit}>
            <input required type="email" placeholder="Enter your email..." />
            <button type="submit">Sign me up</button>
          </form>
        )}
        {status === "success" && (
          <div className="alert success">
            Success! You'll get an email next time I publish something.
          </div>
        )}
        {status === "error" && (
          <div className="alert error">
            Uh oh! Something went wrong. Please refresh and try again.
          </div>
        )}
      </div>
    </div>
  );
};

export { NewsletterBlock };
