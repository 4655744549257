/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Link } from "gatsby";

export const Header = () => {
  const styles = css`
    with: 100vw;
    height: 80px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    .t2 {
      max-width: 1537px;
      width: 100%;
      margin: 0 auto;

      a {
        color: inherit;
        text-decoration: none;
      }
    }

    @media screen and (max-width: 1585px) {
      padding-left: 24px;
      padding-right: 24px;
    }

    @media screen and (max-width: 440px) {
      height: 64px;
      .t2 {
        font-size: 20px !important;
      }
    }

    @media screen and (max-width: 400px) {
      height: 56px;
      .t2 {
        font-size: 18px !important;
      }
    }

    @media screen and (max-width: 320px) {
      padding-left: 16px;
      padding-right: 16px;
    }
  `;

  return (
    <div css={styles}>
      <h1 className="t2">
        <Link to="/">Jack-Edward Oliver</Link>
      </h1>
    </div>
  );
};
