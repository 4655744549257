/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { T22 } from "../../components";
import { Fragment } from "react";

import BG from "../../images/bg-comp.jpg";

const contentStyles = css`
  position: relative;

  @media screen and (max-width: 640px) {
    hr {
      margin: 32px auto;
    }
  }
`;

const Default = ({ children }) => {
  const styles = css`
    position: absolute;
    top: 0;
    left: 0;

    height: 80vh;
    width: 100%;
    box-sizing: border-box;
    pointer-events: none;
    overflow: hidden;

    img {
      filter: grayscale(1);
      width: 100%;
      object-fit: cover;
      object-position: bottom;
      display: block;
      height: 100%;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        0deg,
        rgba(18, 18, 18, 1) 0%,
        rgba(18, 18, 18, 0.8) 100%
      );
    }
  `;

  return (
    <Fragment>
      <div className="BG" css={styles}>
        <img
          src="https://source.unsplash.com/collection/4583498/1600x900"
          alt=""
        />
      </div>
      <div css={contentStyles}>
        <T22.Header />
        {children}
        <T22.Footer />
      </div>
    </Fragment>
  );
};

export { Default };
