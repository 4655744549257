/** @jsx jsx */
import { useState, useEffect } from "react";
import { jsx, css } from "@emotion/react";
import { LastFM } from "plugins";

import { MusicCard } from "../../MusicCard";
import { findImage } from "../../utils";

export const TopAlbums = () => {
  const styles = css`
    width: 912px;
    padding-left: 24px;

    > .t3 {
      opacity: 0.6;
    }

    @media screen and (max-width: 1280px) {
      max-width: 600px !important;
    }

    @media screen and (max-width: 960px) {
      padding-left: 0;
      margin-top: 40px;
      max-width: unset !important;
      width: 100%;
    }

    .grid-wrap {
      overflow-x: auto;
      scroll-snap-type: x mandatory;
      width: 100%;
      padding-bottom: 24px;
      box-sizing: border-box;
    }

    .AlbumList {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 24px;

      @media screen and (max-width: 1280px) {
        .MusicCard {
          width: 184px !important;
        }
      }

      @media screen and (max-width: 960px) {
        .MusicCard {
          width: 100% !important;
        }
      }

      @media screen and (max-width: 740px) {
        gap: 16px;
        row-gap: 24px;
      }

      @media screen and (max-width: 520px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 8px;
        row-gap: 40px;
      }

      @media screen and (max-width: 400px) {
        grid-template-columns: repeat(3, 1fr);
        width: 300%;

        .MusicCard {
          scroll-snap-align: start;
        }
      }
    }
  `;

  const [topAlbums, setTopAlbums] = useState([]);

  useEffect(() => {
    LastFM()
      .User.getTopAlbums(3)
      .then((response) => {
        setTopAlbums(
          response.topalbums.album.map((node) => ({
            name: node.name,
            artist: node.artist.name,
            images: node.image.map((imageNode) => ({
              size: imageNode.size,
              text: imageNode["#text"],
            })),
          }))
        );
      })
      .catch(console.error);
  }, []);

  return (
    <div css={styles}>
      <div className="t3">Top Albums</div>
      <div className="grid-wrap">
        <div className="AlbumList">
          {topAlbums.map((album) => (
            <MusicCard
              key={album.name}
              title={album.name}
              artist={album.artist}
              image={findImage({
                size: "extralarge",
                imageNodes: album.images,
              })}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
